import React from 'react'
import Gbi from './aboutTeporalBG'
import styled from 'styled-components'
import Header from '../header/header'
import media from '../media'

const StyledAboutContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
padding: 10% 12%;

`
const StyledAboutHeader = styled.h1`
${media.mobileS`
width:80%;
font-size:2rem;
margin-top:100px;
`}
${media.laptop`
margin-top:0;
`}
color:orange;
font-size:2.5rem;

`
const StyledAboutSubHeader = styled.h2`
text-transform:capitalize;
${
    media.mobileS`
   font-size:1.5rem;
    `
  }
  ${
    media.tablet`
   font-size:2rem;
    `
  }

color:white;

padding-top:5%;
`
const StyledAboutText = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:60%;
   font-size:1rem;
    `
  }
  
color:white;
font-family:sans-serif;

`
const StyledTextDecal = styled.div`
background-color:orange;
width:15%;
height:4px;
/* margin-bottom:5%; */
`
const aboutTemporal = () => {
    return (
       <Gbi>
           <Header/>
           <StyledAboutContainer>
        <StyledAboutHeader style={{textTransform:"uppercase"}}>
          About Temporal
        </StyledAboutHeader >
        <StyledTextDecal/>
        <StyledAboutSubHeader>Temporal saves you time and money</StyledAboutSubHeader>
        <StyledAboutText>
        Temporal is an enterprise-grade storage solution featuring an easy to use API that can be integrated into any existing application stack. 
        </StyledAboutText>
        <StyledAboutText>
        It provides all the benefits of Web 3.0 without the burden of running complex infrastructure. Currently supporting public and private IPFS+IPNS , with future support for additional protocols like Amazon S3.  
        </StyledAboutText>  
        </StyledAboutContainer>
       </Gbi>
    )
}

export default aboutTemporal

import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import media from '../media'
import ContactBG from './contactUsBg'

const StyledContactContainer = styled.div`
display:flex;
justify-content:center;


`
const StyledContactText = styled(Link)`
${media.mobileS`
font-size:1.2rem;
margin:auto;
margin-top:25%;
`}

${media.mobileL`
margin-top:20%;
`}
${media.tablet`
margin-top:10%;
`}
${media.laptop`
margin-top:8%;
`}
${media.laptopL`
margin-top:15%;
font-size:1.6rem;
`}
${media.desktop`
font-size:2rem;
`}
font-family:sans-serif;
font-weight:bold;
color:lightgray;
text-decoration:none;
&:hover{
    color:orange;
}
`

const contactUs = () => {
    return (
       <ContactBG>
           <StyledContactContainer>
            <StyledContactText to="/contact">Get In Touch With Us</StyledContactText>
           </StyledContactContainer>
        </ContactBG>
    )
}

export default contactUs

import React from 'react'
import Gbi from './aboutRtradeBG'
import styled from 'styled-components'
import media from '../media'

const StyledRTContainer = styled.div`
${media.mobileS`
align-items:center;
margin-right:0;
`}
${media.tablet`
align-items:flex-end;
margin-right:7%;
`}
display:flex;
flex-direction:column;
padding-top:35%;


`
const StyledRTTitle = styled.h1`
${media.mobileS`
font-size:2rem;
text-align:center;
width:90;
/* margin:auto; */
`}
${media.tablet`
font-size:2.5rem;
`}
color:orange;

`
const StyledRTSubTitle = styled.h2`
${media.mobileS`
font-size:1.3rem;
text-align:center;
`}
${media.tablet`
font-size:1.4rem;
`}
text-transform:capitalize;
color:black;
padding-top:5%;
`
const StyledRTText = styled.p`
${media.mobileS`
width:90%;
text-align:justify;
`}
${media.tablet`
width:70%;
`}
${media.laptop`
width:50%;
`}
color:black;
font-family:sans-serif;
font-size:1rem;

`
const StyledTextDecal = styled.div`
${media.mobileS`
width:70%;
margin:auto;
`}
${media.tablet`
width:15%;
margin:0;
`}
background-color:orange;
height:4px;
/* margin-bottom:5%; */
`

const aboutRtrade = () => {
    return (
        <Gbi>
            <StyledRTContainer>
                <StyledRTTitle>About RTrade</StyledRTTitle>
                <StyledTextDecal/>
                <StyledRTSubTitle>RTrade is the technology company that created Temporal</StyledRTSubTitle>
                <StyledRTText>
                Based out of Vancouver, British Columbia, Canada, RTrade team shares a common understanding of the fundamentals of P2P technology and the potential this technology has to improve our lives.RTrade is comprised of specialists in finance, technology, business, and security. Diversity and experience are our greatest strengths, and we are devoted to helping shape the future of technology so that all of humanity can grow, connect, learn and prosper together.</StyledRTText>
            </StyledRTContainer>

        </Gbi>
    )
}

export default aboutRtrade

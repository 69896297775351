import React from "react"
import Layout from "../components/layout"
import AboutSection1 from '../components/about/aboutTemporal'
import AboutSection2 from '../components/about/aboutRtrade'
import TeamSection from '../components/about/teamSection'
import Contact from '../components/about/contactUs'
import MobileHeader from '../components/header/headerMobile'
// import CompanyMap from '../components/about/companyMap'

const about = () => {
  return (
  <Layout>

  <MobileHeader/>
  <AboutSection1/>
  <AboutSection2/>
  <TeamSection/>
  <Contact/>
  {/* <CompanyMap/> */}

    </Layout>
  )}

export default about

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../media'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className,children }) => (
    <StaticQuery query={query}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (
      
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#040e18`}
          >
            {children}
          </BackgroundImage>
          
       )
     }
     }
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
${
    media.mobileS`
    max-height:200px;
    `
  }
  ${
    media.tablet`
    height:100vw;
    `
  }
  ${
    media.laptop`
    height:70vw;
    `
  }
  ${
    media.laptopL`
    min-height:30vw;
    `
  }
  ${
    media.desktop`
    min-height:30vw;
    `
  }
  width: 100%;
  height:60vw;
  background-position:center center;
  background-repeat: repeat-y;
  background-size: cover;
`
export const query = graphql`
query {
  desktop: file(relativePath: { eq:  "bg/ContactSectionBG.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 2560) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`
export default StyledBackgroundSection
import React from 'react'
import Img from 'gatsby-image'
import {StaticQuery, graphql } from 'gatsby'
import media from '../media'
import styled from 'styled-components'


const StyledImagesContainer = styled.div`
${media.mobileS`
flex-direction:column;
`}
${media.tablet`
flex-direction:row;
`}
display:flex;
flex-direction:row;
justify-content:space-around;
align-items:center;
padding-bottom:5%;
margin-top:1%;
`
const StyledAlex = styled.div`
${media.mobileS`
width:60%;
`}
${media.tablet`
width:17%;
`}
width:17%;
margin-top:1%;
display:flex;
flex-direction:column;
align-items:center;

`
const StyledDerrick = styled.div`
${media.mobileS`
width:60%;

`}
${media.tablet`
width:16%;
`}

display:flex;
flex-direction:column;
align-items:center;
`
const StyledCathy = styled.div`
${media.mobileS`
width:60%;
`}
${media.tablet`
width:17%;
`}
margin-top:1%;
display:flex;
flex-direction:column;
align-items:center;
`

const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
const StyledTitle = styled.h1`
font-size:2rem;
font-family:sans-serif;
padding-top:3%;
color:white;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`
const StyledTeamMember = styled.a`
text-align:center;
font-size:1.5rem;
color:white;
font-family:sans-serif;
text-decoration:none;
&:hover{
  color:orange;
}
`
const StyledTeamTitle = styled.p`
font-size:1rem;
color:white;
font-family:sans-serif;
`
const StyledImageContainer = styled.div`
width:100%;
padding-bottom:5%;
`
const StyledTeamMemberContainer = styled.div`
background-color:#212121;
`
// const StyledTeamTitleContainer = styled.div`
// display:flex;
// flex-direction:row;
// width:100%;
// justify-content:center;
// `
// const StyledLinkedinContainer = styled.div`
// width:10%;
// `
const teamSection = () => (

    <StaticQuery
      query={ImagesQ}
      render={data =>
        <StyledTeamMemberContainer>
          
          
          <StyledTitleContainer>
              <StyledTitle>
                Company Founders
              </StyledTitle>          
              <StyledTextDecal/> 
          </StyledTitleContainer>
          <StyledImagesContainer >


                
                  <StyledDerrick>
                      <StyledImageContainer >
                      <Img style={{borderRadius: "50%",}} fluid={data.image2.childImageSharp.fluid} />
                      </StyledImageContainer >
                      <StyledTeamMember href="https://www.linkedin.com/in/derrickfoote86/">Derrick Foote</StyledTeamMember>
                      <StyledTeamTitle>CEO</StyledTeamTitle>
                  </StyledDerrick>
                  <StyledAlex>
                      <StyledImageContainer>
                              <Img style={{borderRadius: "50%",}} fluid={data.image1.childImageSharp.fluid} />
                      </StyledImageContainer >
                      <StyledTeamMember href="https://www.linkedin.com/in/alexandre-trottier-872a118b/">Alexandre Trottier</StyledTeamMember>
                      <StyledTeamTitle>CTO</StyledTeamTitle>
                  </StyledAlex>
                  <StyledCathy>
                        <StyledImageContainer >
                        <Img style={{borderRadius: "50%",}} fluid={data.image3.childImageSharp.fluid} />
                        </StyledImageContainer >
                        <StyledTeamMember href="https://www.linkedin.com/in/cathy-lai-024493a6/">Cathrine Lai</StyledTeamMember>                      
                        <StyledTeamTitle>CSO</StyledTeamTitle>                           
                  </StyledCathy>
              


          </StyledImagesContainer>          
        </StyledTeamMemberContainer>
        }
    />
  

)

export const ImagesQ = graphql`
  fragment TeamSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "team/alex.jpg" }) {
      ...TeamSectionImage
    }

    image2: file(relativePath: { eq: "team/derrick.jpg" }) {
      ...TeamSectionImage
    }
    image3: file(relativePath: { eq: "team/cathy.jpg" }) {
      ...TeamSectionImage
    }
    linkedin: file(relativePath: { eq: "team/linkedin.png" }) {
      ...TeamSectionImage
    }
   

  }
`

export default teamSection